.findJobs-container{
    width: 100%;
    padding: 2% 8%;
}
.findJobs-container p:nth-of-type(1){
    font-size: 2.2vw;
    font-weight: 500;
    margin-bottom: 1%;
}
.findJobs-container #search{
    background-color: #c4dfa6;
    color: #016a70;
    padding: 1% 0.8%;
    border-radius: 10px;
    border-style: solid;
    font-size: 1.0vw;
    font-weight: 500;
    width: 30%;
    margin-bottom: 2%;
}

.findJobs-container .job-container{
    padding-left: 0.5%;
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-bottom: 2%;
}

.findJobs-container .job-container .job-container-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.findJobs-container .job-container .job-container-header .job-container-header-left{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
}
.findJobs-container .job-container .job-container-header .job-container-header-left p{
    color: #016a70;
    font-size: 1.2vw;
    font-weight: 600;
    margin: 0%;
}
.findJobs-container .job-container .job-container-header .job-container-header-left button{
    background-color: #a2c579;
    color: white;
    font-size: 0.9vw;
    font-weight: 500;
    border-style: none;
    height: 60%;
    padding: 0% 5%;
    border-radius: 1vw;
    margin-left: 4%;
}

.findJobs-container .job-container .job-container-header .job-container-header-right p{
    color: #016a70;
    font-size: 1.2vw;
    font-weight: 600;
    margin-bottom: 1%;
}

.findJobs-container .job-container .job-info p{
    margin-top: 0%;
    font-size: 1vw;
    font-weight: 400;
}

.findJobs-container .job-container .job-apply button{
    background-color: #016a70;
    color: white;
    font-size: 1vw;
    font-size: 600;
    border-style: none;
    border-radius: 1vw;
    padding: 1% 4%;
    cursor: pointer;
}

@media screen and (min-width:0px) and (max-width:500px){
    .findJobs-container p:nth-of-type(1){
        font-size: 4.2vw;
        font-weight: 500;
        margin-bottom: 2%;
    }
    .findJobs-container #search{
        padding: 2% 1.8%;
        font-size: 2.0vw;
        width: 62%;
        margin-bottom: 3%;
    }
    .findJobs-container .job-container{
        width: 65%;
    }
    .findJobs-container .job-container .job-container-header{
        align-items: center;
    }
    .findJobs-container .job-container .job-container-header .job-container-header-left{
        width: 70%;
    }
    .findJobs-container .job-container .job-container-header .job-container-header-left p{
        font-size: 2.5vw;
    }
    .findJobs-container .job-container .job-container-header .job-container-header-left button{
        font-size: 1.8vw;
        padding: 2% 5%;
    }
    .findJobs-container .job-container .job-container-header .job-container-header-right p{
        font-size: 2.2vw;
    }

    .findJobs-container .job-container .job-info p{
        font-size: 2vw;
        line-height: 3vw;
    }

    .findJobs-container .job-container .job-apply button{
        font-size: 2vw;
        padding: 2% 5%;
    }
}