.myJobs-container{
    width: 80%;
    padding: 4% 5%;
}

.myJobs-container .myJobs-content{
    display: flex;
    flex-direction: column;
}
.myJobs-container .myJobs-content p{
    font-size: 2.2vw;
    font-weight: 500;
    margin: 0%;
    margin-bottom: 3%;
}

.myJobs-container .myJobs-content .job-container{
    display: flex;
    flex-direction: column;
    width: 65%;
    margin-bottom: 3%;
}

.myJobs-container .myJobs-content .job-container .header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.myJobs-container .myJobs-content .job-container .header p{
    font-size: 1.1vw;
    font-weight: 600;
    color: #016a70;
    margin-bottom: 1.5%;
}

.myJobs-container .myJobs-content .job-container .body p{
    margin: 0%;
    margin-bottom: 1.2%;
    font-size: 1vw;
    font-weight: 400;
}

.myJobs-container .myJobs-content .job-container button{
    background-color: #016a70;
    color: white;
    font-size: 1vw;
    font-weight: 500;
    width: 11%;
    padding: 0.7% 1%;
    border-radius: 0.7vw;
    border-style: none;
    cursor: pointer;
}

@media screen and (min-width:500px) and (max-width:880px){
    .myJobs-container{
        padding: 5% 7%;
    }
    .myJobs-container .myJobs-content .job-container{
        width: 70%;
    }
    .myJobs-container .myJobs-content p{
        font-size: 2.5vw;
    }
    .myJobs-container .myJobs-content .job-container{
        width: 90%;
    }
    .myJobs-container .myJobs-content .job-container .header p{
        font-size: 1.5vw;
    }
    
    .myJobs-container .myJobs-content .job-container .body p{
        font-size: 1.2vw;
    }
    .myJobs-container .myJobs-content .job-container button{
        font-size: 1.2vw;
    }
}

@media screen and (min-width:0px) and (max-width:500px){
    .myJobs-container{
        padding: 5% 7%;
    }
    .myJobs-container .myJobs-content p{
        font-size: 4vw;
    }
    .myJobs-container .myJobs-content .job-container{
        width: 90%;
        margin-bottom: 6%;
    }
    .myJobs-container .myJobs-content .job-container .header p{
        font-size: 2.5vw;
        margin-bottom: 1.5%;
    }
    
    .myJobs-container .myJobs-content .job-container .body p{
        margin-bottom: 2%;
        font-size: 2vw;
    }
    .myJobs-container .myJobs-content .job-container button{
        font-size: 2vw;
        width: 17%;
        padding: 1.3% 1%;
        border-radius: 1vw;
    }
}