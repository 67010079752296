.post-job-container{
    width: 100%;
    padding-left: 6%;
    padding-top: 3%;
}
.post-job-container .post-job-content{
    display: flex;
    flex-direction: column;
}

.post-job-container .post-job-content p{
    font-size: 1.5vw;
    font-weight: 500;
    color: #016a70;
    padding-left: 0.4%;
    margin-bottom: 1%;
}

.post-job-container .post-job-content input{
    background-color: #85aaac;
    font-size: 1vw;
    padding: 1.2% 0.5%;
    color: white;
    border-style: none;
    border-radius: 1vw;
}
.post-job-container .post-job-content input:nth-of-type(1){
    width: 45%;
}
.post-job-container .post-job-content input:nth-of-type(2){
    width: 10%;
    margin-bottom: 2%;
}
.post-job-container .post-job-content #job-description{
    background-color: #85aaac;
    color: white;
    padding: 1.0% 0.7%;
    width: 45%;
    margin-bottom: 1%;
    font-size: 1vw;
    resize: none;
    border-style: none;
    border-radius: 1vw;
}
.post-job-container .post-job-content button{
    background-color: #016a70;
    color: white;
    width: 6%;
    font-size: 1vw;
    padding: 0.5% 0.5%;
    border-radius: 1vw;
    border-style: none;
    font-weight: 500;
    cursor:pointer;
}

@media screen and (min-width:0px) and (max-width:500px){
    .post-job-container{
        padding-left: 8%;
        padding-top: 5%;
    }
    
    .post-job-container .post-job-content p{
        font-size: 3vw;
    }
    
    .post-job-container .post-job-content input{
        font-size: 2vw;
        padding: 1.2% 1%;
    }
    .post-job-container .post-job-content input:nth-of-type(1){
        width: 65%;
    }
    .post-job-container .post-job-content input:nth-of-type(2){
        width: 20%;
    }
    .post-job-container .post-job-content #job-description{
        padding: 1.0% 1.4%;
        width: 65%;
        margin-bottom: 1.5%;
        font-size: 2vw;
    }
    .post-job-container .post-job-content button{
        background-color: #016a70;
        color: white;
        width: 10%;
        font-size: 2vw;
        padding: 1% 0.5%;
        border-radius: 1vw;
        border-style: none;
        font-weight: 500;
    }
}