

#chatbox-container{
    width:100vw;
    height:100vh;
    background-color: white;
}

#chatbox{
    width:100vw;
    height:75vh;
    background-color: aqua;
    background-image: url("../assets/chatbg.png");
    background-size: cover;
    overflow-y: scroll;
    display:flex;
    flex-direction: column;

}

#chatbox p{
    background-color: #c4e0a2;
    display:inline-block;
    width:fit-content;
    padding:1%;
    margin-left: 4%;
    color: #2c4444;
    max-width:50%;
    border-radius: 25px;
    text-align: justify;
}



#chat-input{
    width:100vw;
    height:15vh;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

}

#chat-input input{
    width:80%;
    padding:1%;
    border-radius: 25px;
    border:none;
    background-color: #016a70;
    color:white;
}

#chat-input button{
    width:10%;
    padding:1%;
    border-radius: 25px;
    color:white;
    font-weight: 600;
    background-color: #016a70;
    border:none;
    cursor:pointer;
}

@media  screen and (min-width:0px) and (max-width:900px)  {

    #chatbox p{
        max-width: 80%;
        padding:5%;
    }

    #chat-input{
        height:10vh;
    }

    #chatbox{
        height:85vh;
    }

    #chat-input input{
        width:70%;
        padding:3%;
    }

    #chat-input button{
        padding:3%;
        width:20%;
    }
}