.login-content{
    margin-top: 8%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.login-content p{
    margin:0;
}
.login-content p:nth-of-type(1){
    font-size: 3.5vw;
    margin-bottom: 1%;
    font-weight: 500;
}
.login-content p:nth-of-type(2){
    font-size: 2vw;
    margin-bottom: 2.5%
}

.login-content input{
    margin: 0.5% 0.8%;
    padding: 1% 1.5%;
    width: 13%;
    /* height: 4vh; */
    font-size: 0.9vw;
    border-radius: 0.9vw;
    border: none;
    background-color: #4d7978;
    color: white;
    color-scheme: dark;
}
.login-content input::placeholder{
    color: white;
    /* font-weight: bold; */
}

.login-content p:nth-of-type(2){
    /* width: 14vw; */
    font-size: 1.1vw;
    margin-top: 1%;
    /* line-height: 3vh; */
    text-align: center;
}
.login-content .signup{
    display: inline;
    font-size: 1.1vw !important;
    cursor: pointer;
    color: #016a70;
}
.login-content .signup:hover{
    text-decoration:underline;
    color: #00565a;
}
.login-content p:nth-of-type(3){
    /* width: 14vw; */
    font-size: 1vw;
    margin-top: -1.5%;
    margin-bottom: 1%;
    color: #003f42;
    /* line-height: 3vh; */
    /* text-align: center; */
}

.login-content p:nth-of-type(3):hover{
    text-decoration: underline;
}

.login-content #login-button{
    padding: 0.6% 1.8%;
    font-size: 1.1vw;
    font-weight: 400;
    border: none;
    border-radius: 0.5vw;
    background-color: #a2c579;
    color: white;
    cursor: pointer;
}
.login-content #login-button:hover{
    background-color: #88b654;
    color: white;
}

@media screen and (min-width:0px) and (max-width:500px){
    .login-content{
        margin-top: 20%;
    }
    .login-content p:nth-of-type(1){
        font-size: 8vw;
    }
    .login-content p:nth-of-type(2){
        font-size: 5vw;
        margin-bottom: 2%;
    }
    .login-content input:nth-of-type(1){
        margin-top:5%;
    }
    .login-content input{
        /* margin-top:10%; */
        padding: 2% 3%;
        width: 38%;
        /* height: 3.5vh; */
        font-size: 3vw;
    }

    .login-content p:nth-of-type(2){
        margin-top: 4%;
        width: 60%;
        font-size: 3vw;
        /* line-height: 2vh; */
    }
    .login-content .signup{
        font-size: 3vw !important;
    }
    .login-content p:nth-of-type(3){
        text-align: center;
        width: 40%;
        font-size: 2.7vw;
        margin-top: 0.8%;
        /* line-height: 2vh; */
    }

    .login-content #login-button{
        /* padding: 1.2vh 4vw; */
        margin-top: 2.5%;
        padding: 1% 2%;
        font-size: 3vw;
        border-radius: 0.7vw;
    }
}