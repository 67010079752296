.review-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 5%;
}

.review-container .review-container-left{
    display: flex;
    flex-direction: column;
}
.review-container .review-container-left p:nth-of-type(1){
    margin-top: 0;
    font-size: 2vw;
    font-weight: 500;
    margin-left: 1%;
}
.review-container .review-container-left textarea{
    width: 40vw;
    height: 23vh;
    background-color: #4d7978;
    padding: 3%;
    color: white;
    border: none;
    border-radius: 1vw;
    resize: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}
.review-container .review-container-left .rating-stars{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.review-container .review-container-left .rating-stars p:nth-of-type(1){
    color:#016a70;
    font-weight: 500;
    font-size: 1.2vw;
    margin-top: 3%;
    margin-bottom: 3%;
    margin-left: 1%;
    margin-right: 3%;
    display: inline-block;
}
.review-container .review-container-left .rating-stars .star{
    cursor: pointer;
}
.review-container .review-container-left button{
    background-color: #4d7978;
    color: white;
    font-weight: 500;
    font-size: 1vw;
    border: none;
    border-radius: 1vw;
    width: 20%;
    padding: 2% 0%;
    margin: 0%;
    cursor: pointer;
}
.review-container .review-container-left button:hover{
    background-color: #016a70;
}

.review-container .review-container-right img{
    width: 400px;
}

input[type=radio]{
    display: none;
}
@media screen and (min-width:810px) and (max-width:1010px){
    .review-container .review-container-right img{
        width: 300px;
    }
}

/* ipad */
@media screen and (min-width:500px) and (max-width:810px){
    .review-container .review-container-left p:nth-of-type(1){
        font-size: 4vw;
    }
    .review-container .review-container-right img{
        width: 200px;
    }
    .review-container .review-container-left textarea{
        width: 50vw;
        height: 15vh;
    }
    .review-container .review-container-left .rating-stars p:nth-of-type(1){
        margin: 6% 0%;
        font-size: 2vw;
    }
    .review-container .review-container-left button{
        font-size: 1.8vw;
    }
}

/* mobile */
@media screen and (min-width:0px) and (max-width:500px){
    .review-container{
        margin-top: 15%;
    }
    .review-container .review-container-left{
        justify-content: center;
        align-items: center;
    }
    .review-container .review-container-left p:nth-of-type(1){
        font-size: 6vw;
    }
    .review-container .review-container-left textarea{
        width: 70vw;
        height: 15vh;
    }
    .review-container .review-container-left .rating-stars p:nth-of-type(1){
        margin: 10% 0%;
        font-size: 4vw;
    }
    .review-container .review-container-left button{
        font-size: 3vw;
        width: 40%;
    }
    .review-container .review-container-right {
        display: none;
    }
}