.view-providers-container{
    padding-top: 2%;
    padding-left: 5%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.view-providers-container #heading{
    font-size: 1.8vw;
    font-weight: 500;
}

.view-providers-container .search-bar{
    display: flex;
    flex-direction: row;
}
.view-providers-container .search-bar input{
    background-color: #c4dfa6;
    color: #016a70;
    padding: 1% 0.8%;
    border-radius: 10px;
    border-style: solid;
    font-size: 1.2vw;
    font-weight: 500;
    width: 30%;
}
.view-providers-container .search-bar select{
    background-color: #c4dfa6;
    color: #016a70;
    margin-left: 1.2%;
    border-radius: 10px;
    border-style: solid;
    padding: 0% 1%;
    font-weight: 500;
    font-size: 1vw;
}

.view-providers-container .provider-container{
    margin-top: 3%;
    display: flex;
    flex-direction: column;
    width: 47%;

}
.view-providers-container .provider-container .provider-name{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.view-providers-container .provider-container .provider-name #review-star{
    color: #f4c01e;
    font-size: 1vw;
    padding-left: 0.5%;
}
.view-providers-container .provider-container .provider-name p{
    color: #016a70;
    font-size: 1.3vw;
    font-weight: 500;
    margin: 0%;
}
.view-providers-container .provider-container .provider-name p:nth-of-type(1){
    padding-right: 2%;
}
.view-providers-container .provider-container .provider-name p:nth-of-type(2){
    font-weight: 600;
}
.view-providers-container .provider-container .provider-info p{
    margin: 0%;
    margin-top: 1.2%;
    font-size: 1vw;
    line-height: 120%;
}
.view-providers-container .provider-container .provider-skills ul{
    display: flex;
    flex-direction: row;
    margin: 3% 0%;
    font-size: 1.1vw;
    font-weight: 500;
    color: #016a70;
}
.view-providers-container .provider-container .provider-skills ul li:nth-of-type(1){
    margin-left: 0%;
}
.view-providers-container .provider-container .provider-skills ul li{
    margin-left: 5%;
}
.view-providers-container .provider-container .provider-skills p{
    color: #016a70;
    font-weight: 600;
    font-size: 1vw;
}
.view-providers-container .provider-container button{
    background-color: #6ca8ac;
    color: white;
    font-size: 1vw;
    font-weight: 500;
    width: 18%;
    padding: 1.7% 2%;
    border-style: none;
    border-radius: 1vw;
    cursor: pointer;
}
.view-providers-container .provider-container button:hover{
    background-color: #016a70;
}

@media screen and (min-width:0px) and (max-width:500px){
    .view-providers-container #heading{
        font-size: 4.2vw;
    }
    .view-providers-container .search-bar input{
        padding: 2% 1.8%;
        font-size: 2.2vw;
        width: 42%;
    }
    .view-providers-container .search-bar select{
        padding: 1% 2%;
        font-size: 2vw;
    }
    .view-providers-container .provider-container{
        margin-top: 5%;
        width: 75%;
    }
    .view-providers-container .provider-container .provider-name #review-star{
        font-size: 2.3vw;
    }
    .view-providers-container .provider-container .provider-name p{
        font-size: 3vw;
    }
    .view-providers-container .provider-container .provider-info p{
        margin-top: 2%;
        font-size: 2vw;
    }
    .view-providers-container .provider-container .provider-skills ul{
        font-size: 2.1vw;
        padding-left: 2%;
    }
    .view-providers-container .provider-container button{
        font-size: 2.2vw;
        padding: 2.7% 3%;
    }
}