.profile-container{
    width: 100%;
}
.profile-content{
    display: flex;
    /* background-color: rgb(255, 162, 162); */
    flex-direction: column;
    margin: 3% 5%;
}
.profile-container .profile-content .info{
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    /* background-color: rgb(187, 252, 230); */
}
.profile-container .profile-content .info .profile-image{
    padding-top: 1%;
    display: flex;
    width:250px;
    height:250px;
    /* background-color: rgb(180, 132, 224); */
    padding-right:0;
    /* justify-content: flex-end; */
    /* background-image: url("../assets/userImage.jpeg"); */
    /* background-size: cover; */
}
.profile-container .profile-content .info .profile-image #saveImgButton{
    /* margin-bottom: 50%; */
    background-color: white;
    border-radius: 10px;
    padding: 1% 2%;
    border-style: solid;
    border-color:#016a70;
    font-weight: 500;
}
.profile-container .profile-content .info #edit_img{
    /* top:12.9%;
    left:18.1%; */
    margin-left: 1%;
}
.profile-container .profile-content .info .profile-image img{
    /* margin-top: 4%; */
    width: 100%; 
    /* background-color: red; */
    border-radius: 10px;
    object-fit:cover;
}
.profile-container .profile-content .info .profile-name-info{
    display: flex;
    flex-direction: column;
    /* background-color: rgb(240, 151, 35); */
    margin-left: 3%;
    width: 50%;
}
/* #dynamic-input {
    width: auto; 
    min-width: 50px; 
} */
.profile-container .profile-content .info .profile-name-info .profile-name{
    margin-bottom: 0.3%;
}
.profile-container .profile-content .info .profile-name-info .profile-name #nameEdit{
    padding-bottom: 1.2%;
    padding-left: 2%;
}
.profile-container .profile-content .info .profile-name-info .profile-name #check{
    padding-bottom: 1.2%;
    padding-left: 2%;
}
.profile-container .profile-content .info .profile-name-info .profile-info .editImage{
    padding-bottom: 1.2%;
    padding-left: 2%;
}
.profile-container .profile-content .info .profile-name-info .profile-info #check{
    padding-bottom: 1.2%;
    padding-left: 2%;
}
.profile-container .profile-content .info .profile-name-info input{
    font-size: 2vw;
    font-weight: 500;
    margin:0% 0%;
    margin-top: 0%;
    border: none;
    padding-left: 2%;
    width: auto; 
    min-width: 50px;
}
.profile-container .profile-content .info .profile-name-info textarea{
    font-size: 1vw;
    font-weight:400;
    margin: 0%;
    padding: 3% 3%;
    resize: none;
    border: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    height: auto;
    min-height: 50px;
}

/* Languages */
.profile-container .profile-content .languages{
    /* margin-top: 5%; */
    display: flex;
    flex-direction: column;
}
.profile-container .profile-content .languages .language-heading{
    /* background-color: rgb(255, 131, 131); */
    display: flex;
}
.profile-container .profile-content .languages .language-heading p:nth-of-type(1){
    margin-right: 1%;
    font-size: 1.7vw;
    font-weight: 500;
}
.profile-container .profile-content .languages .language-heading #lang{
    align-self: center;
}
.profile-container .profile-content .languages .languages-list{
    display: flex;
    flex-direction: column;
    width: 10%;
}
.profile-container .profile-content .languages .languages-list .language-item{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.profile-container .profile-content .languages .languages-list img{
    width: 70px;
}
.profile-container .profile-content .languages .languages-list p{
    padding-top: 0.1%;
    padding-left: 0.7%;
    font-size: 1vw;
    font-weight: 500;
    color: #016a70;
}

/* education section */
.profile-container .profile-content .education-container p{

    font-size: 1.7vw;
    font-weight: 500;
    margin: 1% 0%;

}
.profile-container .profile-content .education{
    display: flex;
    align-items: center;
}
.profile-container .profile-content .education{
    display: flex;
    align-items: center;
}
.profile-container .profile-content .education #education-icon{
    color: #016a70;
    font-size: 1.7vw;
}
.profile-container .profile-content .education p{
    padding-left: 0.8%;
    padding-top: 0.2%;
    font-size:1vw;
    font-weight: 500;
    color: #016a70;
    margin-bottom: 1%;
}

/* Review section */

.profile-container .profile-content .review-container{
    margin-top: 0%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: start;
}
.profile-container .profile-content .review-container p{
    font-size: 1.7vw;
    font-weight: 500;
    /* margin: 7% 0%; */
}
.profile-container .profile-content .review-container .review{
    display: flex;
    flex-direction: column;
    margin-bottom: 4%;
}
.profile-container .profile-content .review-container .review .review-details{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.profile-container .profile-content .review-container .review .review-message p{
    font-size: 1.1vw ;
    font-weight: 400;
    margin: 0px;
}
.profile-container .profile-content .review-container .review .review-details p{
    margin: 0%;
    margin-top: 1%;

}
.profile-container .profile-content .review-container .review .review-details p:nth-of-type(1){
    color: #016170;
    font-weight: 500;
    font-size: 0.9vw;
}
.profile-container .profile-content .review-container .review .review-details p:nth-of-type(2){
    color: #016170;
    font-weight: 400;
    font-size: 0.85vw;
}
.profile-container .profile-content .review-container .review .review-details .star{
    cursor: pointer;
    margin-top: 1%;
    padding-left: 0.3%; 
}
.profile-container .profile-content .review-container .review .review-details .star:nth-of-type(1){
    cursor: pointer;
    margin-top: 1%;
    margin-left: 1%;
}

@media screen and (min-width:500px) and (max-width:880px){
    .profile-container .profile-content .info .profile-image{
        width: 160px !important;
        height: 160px !important;
    }
    .profile-container .profile-content .info .profile-image .editAvatar .profile-picture{
        width:150px;
        height:150px;
    }
    .profile-container .profile-content .info .profile-image #cropped-profile-picture{
        /* margin-top: 15%; */
        width:150px;
        height:150px;
    }
    svg{
        width: 10px;
    }
    .profile-container .profile-content .info .profile-name-info .profile-name #nameEdit{
        width: 10px;
    }
    .profile-container .profile-content .info .profile-name-info .profile-name .check{
        width: 10px;
    }
    .profile-container .profile-content .info .profile-name-info .profile-info .check{
        width: 10px;
    }
    .profile-container .profile-content .info .profile-name-info .profile-info .editImage{
        width: 10px;
    }
}

@media screen and (min-width:0px) and (max-width:500px){
    .profile-container .profile-content .info .profile-image .editAvatar .profile-picture{
        width:100px;
        height:100px;
    }
    .profile-container .profile-content .info .profile-image #cropped-profile-picture{
        margin-top: 15%;
        width:100px;
        height:100px;
    }
    .profile-container .profile-content .info .profile-name-info input{
        font-size: 4vw;
    }
    .profile-container .profile-content .info .profile-name-info textarea{
        font-size: 2.1vw;
    }
    .profile-container .profile-content .info .profile-name-info{
        width: 100%;
    }
    svg{
        width: 10px;
    }


    /* languages */

    .profile-container .profile-content .languages .language-heading p:nth-of-type(1){
        font-size: 4.5vw;
    }
    .profile-container .profile-content .languages .language-heading #lang{
        width: 10px;
    }
    .profile-container .profile-content .languages .language-heading .check{
        align-self: center;
        width: 10px;
    }
    .profile-container .profile-content .languages .language-list #languageSelect{
        width: 500%;
    }
    .profile-container .profile-content .languages .languages-list .language-item .languageImage{
        width: 60px;
    }
    .profile-container .profile-content .languages .languages-list .language-item p{
        font-size: 2.7vw;
    }

    /* education-section */
    .profile-container .profile-content .education-container p{
        font-size: 4.5vw;
        font-weight: 500;
        margin: 0px;
    }
    .profile-container .profile-content .education-container .education {
        margin-top: 3%;
    }
    .profile-container .profile-content .education-container .education p{
        font-size: 3vw;
        margin-bottom: 0px;
    }
    .profile-container .profile-content .education-container .education #education-icon{
        font-size: 3.3vw;
    }

    /* review section */
    .profile-container .profile-content .review-container{
        width: 80%;
    }
    .profile-container .profile-content .review-container p:nth-of-type(1){
        font-size: 4.5vw;
        font-weight: 500;
        margin: 7% 0%;
    }
    .profile-container .profile-content .review-container .review .review-message p{
        font-size: 3vw;
        font-weight: 400;
    }
    .profile-container .profile-content .review-container .review .review-details p{
        font-size: 3vw !important;
        font-weight: 400 ;
    }
    #languageSelect{
        width: 35vh;
        height: 10vh;
    }

}

