.ApplyJob-container{
    width: 100%;
    padding: 2% 8%;
}

.ApplyJob-container .ApplyJob-content{
    display: flex;
    flex-direction: column;
    width: 50%;
}

.ApplyJob-container .ApplyJob-content .ApplyJob-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.ApplyJob-container .ApplyJob-content .ApplyJob-header .ApplyJob-header-left{
    display: flex;
    flex-direction: row;
    width: 50%;
    align-items: center;
}
.ApplyJob-container .ApplyJob-content .ApplyJob-header .ApplyJob-header-left p{
    font-size: 1.3vw;
    font-weight: 500;
    color: #016a70;
}
.ApplyJob-container .ApplyJob-content .ApplyJob-header .ApplyJob-header-left button{
    height: 55%;
    margin-left: 2%;
    background-color: #a2c579;
    border-style: none;
    border-radius: 1vw;
    color: white;
    padding: 0% 2.5%;
    font-size: 1vw;
    font-weight: 500;
}

.ApplyJob-container .ApplyJob-content .ApplyJob-header .ApplyJob-header-right p{
    font-size: 1.3vw;
    font-weight: 500;
    color: #016a70;
}

.ApplyJob-container .ApplyJob-content .job-info{
    margin-top: 0%;
    font-size: 1.2vw;
    line-height: 2.9vh;
    font-weight: 300;
}
.ApplyJob-container .ApplyJob-content .job-info p{
    margin-top: 0%;
}

.ApplyJob-container .ApplyJob-content .job-proposal{
    margin-bottom: 3%;
}
.ApplyJob-container .ApplyJob-content .job-proposal textarea{
    padding: 2%;
    border-radius: 1vw;
    border-style: none;
    background-color: #84adab;
    color: white;
    font-size: 1vw;
}

.ApplyJob-container .ApplyJob-content .job-rate{
    margin-bottom: 2%;
}

.ApplyJob-container .ApplyJob-content .job-rate textarea{
    padding: 2%;
    border-radius: 1vw;
    border-style: none;
    background-color: #84adab;
    color: white;
    font-size: 1vw;
}

.ApplyJob-container .ApplyJob-content .job-apply button{
    font-size: 1vw;
    font-weight: 500;
    color: white;
    background-color: #4d7978;
    border-radius: 1vw;
    border-style: none;
    padding: 1.5% 3.5%;
    cursor:pointer;
}

@media screen and (min-width:0px) and (max-width:500px){
    .ApplyJob-container .ApplyJob-content{
        width: 70%;
        margin-top: 5%;
    }
    .ApplyJob-container .ApplyJob-content .ApplyJob-header{
        align-items: center;
    }
    .ApplyJob-container .ApplyJob-content .ApplyJob-header .ApplyJob-header-left{
        width: 70%;
    }
    .ApplyJob-container .ApplyJob-content .ApplyJob-header .ApplyJob-header-left p{
        font-size: 2.3vw;
    }
    .ApplyJob-container .ApplyJob-content .ApplyJob-header .ApplyJob-header-left button{
        padding: 3% 3.5%;
        font-size: 2vw;
    }
    .ApplyJob-container .ApplyJob-content .ApplyJob-header .ApplyJob-header-right p{
        font-size: 2.3vw;
    }
    .ApplyJob-container .ApplyJob-content .job-info{
        font-size: 2.5vw;
        line-height: 2vh;
        font-weight: 400;
    }
    .ApplyJob-container .ApplyJob-content .job-proposal textarea{
        padding: 3%;
        font-size: 2vw;
    }
    .ApplyJob-container .ApplyJob-content .job-rate textarea{
        padding: 3%;
        font-size: 2vw;
    }

    .ApplyJob-container .ApplyJob-content .job-apply button{
        margin-top: 2%;
        font-size: 2vw;
        padding: 2.5% 4.5%;
    }
}

@media screen and (min-width:500px) and (max-width:880px){
    .ApplyJob-container .ApplyJob-content{
        width: 60%;
        margin-top: 3%;
    }
    .ApplyJob-container .ApplyJob-content .ApplyJob-header .ApplyJob-header-left p{
        font-size: 1.8vw;
    }
    .ApplyJob-container .ApplyJob-content .ApplyJob-header .ApplyJob-header-left button{
        padding: 2.5% 3%;
        font-size: 1.5vw;
    }
    .ApplyJob-container .ApplyJob-content .ApplyJob-header .ApplyJob-header-right p{
        font-size: 1.8vw;
    }
    .ApplyJob-container .ApplyJob-content .job-info{
        font-size: 1.5vw;
        line-height: 1.5vh;
        font-weight: 400;
    }
    .ApplyJob-container .ApplyJob-content .job-proposal textarea{
        padding: 2.5%;
        font-size: 1.5vw;
    }
    .ApplyJob-container .ApplyJob-content .job-rate textarea{
        padding: 2%;
        font-size: 1.5vw;
    }

    .ApplyJob-container .ApplyJob-content .job-apply button{
        margin-top: 1.5%;
        font-size: 1.5vw;
        padding: 2% 4%;
    }
}