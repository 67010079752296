.booking-container{
    width: 100%;
    padding-top: 2.5%;
    padding-left: 8%;
}

.booking-container .booking-content{
    display: flex;
    flex-direction: row;
}

.booking-container .booking-content .booking-content-left{
    width: 40%;
}

.booking-container .booking-content .booking-content-left .header{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.booking-container .booking-content .booking-content-left .header p{
    font-size: 2vw;
    font-weight: 500;
}
.booking-container .booking-content .booking-content-left .header #plus{
    font-size: 1.3vw;
    font-weight: 600;
    color: #a2c579;
    padding-left: 3%;
    cursor: pointer;
}

.booking-container .booking-content .booking-content-left .booking-card{
    display: flex;
    flex-direction: column;
    margin-bottom: 4%;
}
.booking-container .booking-content .booking-content-left .booking-card p{
    color: #016a70;
    margin: 0%;
    font-size: 1.2vw;
}

.booking-container .booking-content .booking-content-left .booking-card .booking-card-buttons{
    display: flex;
    flex-direction: row;
    margin: 1.5% 0%;
}
.booking-container .booking-content .booking-content-left .booking-card .booking-card-buttons button{
    border-radius: 1vw;
    border-style: none;
    color: white;
    font-size: 1vw;
    font-weight: 500;
}
.booking-container .booking-content .booking-content-left .booking-card .booking-card-buttons #status{
    width: 20%;
    padding: 1% 1%;
    background-color: #016a70;
    margin-right: 1.2%;
}
.booking-container .booking-content .booking-content-left .booking-card .booking-card-buttons #name{
    width: 20%;
    padding: 1% 1%;
    background-color: #a2c579;
}

.booking-container .booking-content .booking-content-left .booking-card .time{
    display: flex;
    flex-direction: row;
    color: #016a70;
    font-weight: 600;
    margin-bottom: 1%;
}
.booking-container .booking-content .booking-content-left .booking-card .time p:nth-of-type(1){
    padding-right: 1.5%;
}

.booking-container .booking-content .booking-content-left .booking-card .rate-pay{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.booking-container .booking-content .booking-content-left .booking-card .rate-pay p{
    margin-right: 6%;
}
.booking-container .booking-content .booking-content-left .booking-card .rate-pay button{
    border-radius: 1vw;
    border-style: none;
    color: white;
    font-size: 1vw;
    font-weight: 500;
    width: 20%;
    padding: 1% 1%;
    background-color: #a2c579;
}

.booking-container .booking-content .booking-content-right{
    padding-top: 7%;
    padding-left: 3%;
}
.booking-container .booking-content .booking-content-right #booking-image{
    width: 500px;
}

.booking-container .booking-content .booking-content-left .add-booking{
    display: flex;
    flex-direction: column;
    width: 50%;
    margin: 0%;
    margin-bottom: 5%;
    align-items: center;
}
.booking-container .booking-content .booking-content-left .add-booking p{
    text-align: center;
    font-size: 1.5vw;
    font-weight: 500;
    margin-top: 0%;
    margin-bottom: 2.5%;
}
.booking-container .booking-content .booking-content-left .add-booking input{
    background-color: #4d7978;
    color: white;
    padding: 4% 6%;
    margin-bottom: 2%;
    border-radius: 1vw;
    border-style: none;
    font-size: 1vw;
    width: 62.5%;
}
.booking-container .booking-content .booking-content-left .add-booking input:nth-of-type(2){
    width: 62.5%;
}
.booking-container .booking-content .booking-content-left .add-booking button{
    background-color: #4d7978;
    color: white;
    border-radius: 1vw;
    border-style: none;
    width: 30%;
    font-size: 1vw;
    font-weight: 400;
    padding: 3% 5%;
    cursor: pointer;
}


@media screen and (min-width:500px) and (max-width:880px){
    .booking-container .booking-content .booking-content-left .add-booking p{
        font-size: 2vw;
    }
    .booking-container .booking-content .booking-content-left .add-booking input{
        padding: 6% 8%;
        width: 80%;
        font-size: 1.5vw;
    }
    .booking-container .booking-content .booking-content-left .add-booking input:nth-of-type(2){
        width: 80%;
    }
    .booking-container .booking-content .booking-content-left .add-booking button{
        width: 50%;
        font-size: 1.5vw;
        padding: 3% 5%;
    }

    .booking-container{
        width: 90%;
    }
    
    .booking-container .booking-content .booking-content-left{
        width: 50%;
    }
    
    .booking-container .booking-content .booking-content-left .header p{
        font-size: 2vw;
        font-weight: 500;
    }
    .booking-container .booking-content .booking-content-left .header #plus{
        font-size: 2vw !important;
    }
    
    .booking-container .booking-content .booking-content-left .booking-card p{
        font-size: 1.5vw;
    }
    
    .booking-container .booking-content .booking-content-left .booking-card .booking-card-buttons button{
        font-size: 1.3vw;
    }
    .booking-container .booking-content .booking-content-left .booking-card .booking-card-buttons #status{
        width: 25%;
        padding: 1.3% 1%;
    }
    .booking-container .booking-content .booking-content-left .booking-card .booking-card-buttons #name{
        width: 25%;
        padding: 1.3% 1%;
    }

    .booking-container .booking-content .booking-content-left .booking-card .rate-pay p{
        margin-right: 10%;
    }
    .booking-container .booking-content .booking-content-left .booking-card .rate-pay button{
        font-size: 1.3vw;
        width: 25%;
        padding: 1.3% 1%;
    }
    
    .booking-container .booking-content .booking-content-right #booking-image{
        width: 300px;
    }
}




@media screen and (min-width:0px) and (max-width:500px){
    .booking-container .booking-content .booking-content-left .add-booking{
        width: 60%;
    }
    .booking-container .booking-content .booking-content-left .add-booking p{
        font-size: 3vw;
    }
    .booking-container .booking-content .booking-content-left .add-booking input{
        padding: 6% 8%;
        font-size: 2.5vw;
        width: 100%;
    }
    .booking-container .booking-content .booking-content-left .add-booking input:nth-of-type(2){
        width: 100%;
    }
    .booking-container .booking-content .booking-content-left .add-booking button{
        width: 50%;
        font-size: 2.5vw;
        padding: 6% 8%;
    }

    .booking-container{
        width: 80%;
        padding-top: 7%;
    }
    
    .booking-container .booking-content .booking-content-left{
        width: 60%;
    }
    
    .booking-container .booking-content .booking-content-left .header p{
        font-size: 4vw;
    }
    .booking-container .booking-content .booking-content-left .header #plus{
        font-size: 2.9vw;
        padding-left: 5%;
    }
    
    .booking-container .booking-content .booking-content-left .booking-card p{
        font-size: 3vw;
    }

    .booking-container .booking-content .booking-content-left .booking-card{
        margin-bottom: 6%;
    }
    
    .booking-container .booking-content .booking-content-left .booking-card .booking-card-buttons{
        display: flex;
        flex-direction: row;
        margin: 2.3% 0%;
    }
    .booking-container .booking-content .booking-content-left .booking-card .booking-card-buttons button{
        border-radius: 1vw;
        border-style: none;
        color: white;
        font-size: 2.5vw;
        font-weight: 500;
    }
    .booking-container .booking-content .booking-content-left .booking-card .booking-card-buttons #status{
        width: 40%;
        padding: 2% 1%;
        background-color: #016a70;
        margin-right: 1.2%;
    }
    .booking-container .booking-content .booking-content-left .booking-card .booking-card-buttons #name{
        width: 40%;
        padding: 2% 1%;
        background-color: #a2c579;
    }
    
    .booking-container .booking-content .booking-content-left .booking-card .time p:nth-of-type(1){
        padding-right: 3%;
    }

    .booking-container .booking-content .booking-content-left .booking-card .rate-pay p{
        margin-right: 3.3%;
    }
    .booking-container .booking-content .booking-content-left .booking-card .rate-pay button{
        font-size: 2.5vw;
        width: 40%;
        padding: 2% 1%;
    }
    
    .booking-container .booking-content .booking-content-right{
        padding-top: 25%;
        padding-left: 5%;
    }
    .booking-container .booking-content .booking-content-right #booking-image{
        width: 150px;
    }
}